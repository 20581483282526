import React from "react"
import Slider from "react-slick"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slide from "../components/slide"

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import step1 from "../images/lepes1.png"
import step2 from "../images/lepes2.png"
import step3 from "../images/lepes3.png"
import workPage from "../images/munkalap_1.png"
import video from "../images/fk_video1b.png"
import delegateWrong from "../images/feladatkiadas_rosszul.png"
import delegateMobile from "../images/feladatkiadas_mobil1.png"
import appStore from "../images/ios_store.jpg"
import playStore from "../images/gplay.jpg"
import ebbokIcon from "../images/fk_ebook_ikon.png"

import ReactGA from 'react-ga';

ReactGA.initialize('UA-47640313-1');

const settings = {
  dots: true,
  infinite: true,
  speed: 750,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="sized-container" style={{ textAlign: `center` }}>
      <h1 className="toptext">A feladatkiadás az <u>időteremtés</u> egyetlen módja. <br />
          Teremts Te is több időt magadnak!</h1>
    </div>
    <div className="main_slider_container sized-container" >
      <Slider {...settings}>
        <Slide 
            image={step1} 
            imageLink="/app?page=delegate"
            imageLinkTitle="Feladat kiadás, delegálás"
            imageLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', '1step');
              // ga('send', 'event', 'APP', 'Entry', '1step'); 
            }}
            buttonTitle="Próbáld ki! (1 perc)"
            buttonLink="/app?page=delegate"
            buttonLinkTitle="Feladatkiadás, delegálás 3 lépésben"
            buttonLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', '1step');
              // ga('send', 'event', 'APP', 'Entry', '1step'); 
            }}
        >
          <h3>1. lépés</h3>    
          <p>Pontosítsd a feladatot annyira, amennyire a feladatvégzőjének szüksége lehet rá! Add meg:</p>
          <ul>
            <li>nevét (rövid tömör leírás)</li>
            <li>részletes leírását</li>
            <li>határidejét</li>
          </ul>                    
        </Slide>
        <Slide 
            image={step2} 
            imageLink="/app?page=delegate"
            imageLinkTitle="Feladat kiadás, delegálás"
            imageLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', '2step');
              // ga('send', 'event', 'APP', 'Entry', '2step'); 
            }}
            buttonTitle="Próbáld ki! (1 perc)"
            buttonLink="/app?page=delegate"
            buttonLinkTitle="Feladatkiadás, delegálás 3 lépésben"
            buttonLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', '2step');
              // ga('send', 'event', 'APP', 'Entry', '2step'); 
            }}
        >
          <h3>2. lépés (opcionális)</h3>    
          <p>
            Bontsd a nagyobb feladatot részekre vagy adj meg egy csekklistát (ellenőrző listát). <br/>
            Feltölthetsz dokumentumokat, képeket amik segítik a feladat elvégzését. Vagy maga a feladatvégző tölti fel az eredményt ilyen formában.
          </p>        
        </Slide>
        <Slide 
            image={step3} 
            imageLink="/app?page=delegate"
            imageLinkTitle="Feladat kiadás, delegálás"
            imageLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', '3step');
              // ga('send', 'event', 'APP', 'Entry', '3step'); 
            }}
            buttonTitle="Próbáld ki 1 perc alatt!"
            buttonLink="/app?page=delegate"
            buttonLinkTitle="Feladatkiadás, delegálás 3 lépésben"
            buttonLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', '3step');
              // ga('send', 'event', 'APP', 'Entry', '3step'); 
            }}
        >
          <h3>3. lépés</h3>    
          <p>
            Add meg a feladatot végző adatait vagy válaszd ki egyszerűen egy listából, ha már dolgoztál vele. 
            A feladat elvégzője email-ben értesül a kiadott feladatról. Amint átvette, erről Te is értesítést kapsz.
          </p>
        </Slide>

        <Slide 
            image={workPage} 
            imageLink="/app?page=delegate"
            imageLinkTitle="Feladat kiadás, delegálás"
            imageLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', 'Munkalap');
              // ga('send', 'event', 'APP', 'Entry', 'Munkalap'); 
            }}
            buttonTitle="Próbáld ki 1 perc alatt!"
            buttonLink="/app?page=delegate"
            buttonLinkTitle="Feladatkiadás, delegálás 3 lépésben"
            buttonLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', 'Munkalap');
              // ga('send', 'event', 'APP', 'Entry', 'Munkalap'); 
            }}
        >
          <h3>Munkalap</h3>    
          <p>
              A feladat elvégzését a munkalapon nézheted végig, láthatod %-osan a változást, 
              vagy az alfeladatok kipipálását. Itt kommunikálhatsz a feladat végzőjével is vagy lezárhatod, 
              ha elkészült a feladat. Az állapot változásokról email értesítést is kapsz.          
          </p>
        </Slide>

        <Slide 
            image={video} 
            imageLink="/files/feladatkiadas_video1.mp4"
            imageLinkTitle="Feladat kiadás, delegálás"
            imageLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', 'Video');
              // ga('send', 'event', 'APP', 'Entry', 'Video'); 
            }}
            buttonTitle="Próbáld ki 1 perc alatt!"
            buttonLink="/app?page=delegate"
            buttonLinkTitle="Feladatkiadás, delegálás 3 lépésben"
            buttonLinkOnClick={e => { 
              ReactGA.event('APP', 'Entry', 'Video');
              // ga('send', 'event', 'APP', 'Entry', 'Video'); 
            }}
        >
          <h3>Videó a használatról</h3>    
          <p>
            A feladatkiadás 1 perc alatt kipróbálható a lenti gombra kattintva, vagy a működés gyorsan megérthető a balra levő videó segítségével is.
          </p>
        </Slide>

      </Slider>
    </div>

    <div className="delegate_wrong sized-container" >
      <div className="delegate_wrong_image">
        <img src={delegateWrong} alt="Így is lehet"/>
      </div>
      <div className="delegate_wrong_text">
        <p>
          Így is ki lehet adni egy <br />feladatot.
        </p>
        <p>
        De talán lehet jobban is,<br />sőt sokkal jobban...
        </p>
      </div>
      <div style={{float: `none`, clear:`both`}} > </div>
    </div>

    <div className="delegate_mobile sized-container" >
      <div className="delegate_mobile_text">
        <h1>Feladatkiadás mobilról</h1>
        <p>
          Érd el a kiadott feladataidat bárhonnan a mobilod (Android, IOS, iPhone) segítségével! Bárhol is vagy, 
          értesülni fogsz a feladatok állapotáról a haladásáról. Ha kérdés merül fel csak egy kattintás és már 
          válaszolhatsz is.
        </p>
        <p>
          A munkát végző is eléri a feladatokat mobilon, így akár a terepen is jelezheti, ha kérdése van, ha elakadt. 
          Mire használhatod a feladatkiadás mobil alkalmazást?
        </p>
        <ul>
          <li>Küldhetsz bevásárló listát</li>
          <li>Feladatot adhatsz ki munkatársaidnak</li>
          <li>Otthoni munkák megosztására</li>
          <li>Szabadságon, nyaraláskor is képben lehetsz a kiadott feladatokkal</li>
          <li>Rendszeres feladatok delegálása</li>
        </ul>
        <p className="delegate_mobile_stores">
          <a target="blank" href="https://play.google.com/store/apps/details?id=hu.ic.delegoo" title="feladatkiadás android mobil alkalmazás" onClick={e => { ReactGA.event('ANDROID', 'Click', 'GPlay'); /*ga('send', 'event', 'ANDROID', 'Click', 'GPlay');*/ }}>
            <img alt="feladatkiadás google play mobil alkalmazás" src={playStore} />
          </a>
          <a target="blank" href="https://itunes.apple.com/us/app/feladatkiadas/id862364250?l=hu&amp;ls=1&amp;mt=8" title="feladatkiadás ios, apple, iphone mobil alkalmazás" onClick={e => { ReactGA.event('IOS', 'Click', 'AStore'); /*ga('send', 'event', 'IOS', 'Click', 'AStore'); */ }}>
            <img alt="feladatkiadás IOS, apple, iphone mobil alkalmazás" src={appStore} />
          </a>
        </p>
      </div>
      <div className="delegate_mobile_image">
        <a target="blank" href="https://play.google.com/store/apps/details?id=hu.ic.delegoo" title="feladatkiadás android mobil alkalmazás" onClick={e => { ReactGA.event('ANDROID', 'Click', 'GPlay'); /*ga('send', 'event', 'ANDROID', 'Click', 'GPlay'); */ }}>
          <img src={delegateMobile} alt="Mobil" />
        </a>
      </div>
      <div style={{float: `none`, clear:`both`}} > </div>
    </div>

    <div className="delegate_advantages fullwidth-container" >
      <div className="sized-container">
        <h2>Melyek a feladatkiadás, delegálás előnyei?</h2>
        <p>
          Minden ember szeretne több időt, szeretne hatékonyabban, eredményesebben dolgozni, hogy több ideje maradjon a számára fontos dolgokra, 
          a hobbijára, családjára, barátaira. Sajnos  mindannyiunknak  annyi  feladata  van,  amit  egyszerűen  képtelenek  vagyunk  egyedül 
          elvégezni. Hiába dolgozunk többet, keményebben vagy hatékonyabban, mindig lesznek újabb és újabb feladatok, melyeket meg kell 
          oldanunk. Az igazán fontos céljainkra már nem marad idő, pedig ezen lehet változtatni. 
          Töltsd le a <a target="blank" title="Feladatkiadás, delegálás ebook" href="/files/feladatkiadas.pdf" onClick={e => { ReactGA.event('PDF', 'Download', 'Sikeres feladatkiadas'); /* ga('send', 'event', 'PDF', 'Download', 'Sikeres feladatkiadas'); */ }}>
            Sikeres feladatkiadás
          </a> című e-bookot
        </p>
        <p>
          <a target="blank" title="Feladatkiadás, delegálás ebook" href="/files/feladatkiadas.pdf" onClick={e => { ReactGA.event('PDF', 'Download', 'Sikeres feladatkiadas'); /*ga('send', 'event', 'PDF', 'Download', 'Sikeres feladatkiadas'); */ }}>
            <img align="right" hspace="10px" src={ebbokIcon} alt="Delegálás ebook" />
          </a>
        </p>
        <p>
          Ez a szolgáltatás arról az időgazdálkodási módszerről szól, melynek megfelelő alkalmazása lehetővé teszi számunkra,  hogy  sokkal  
          több  időnk  maradjon,  így  elérhetjük  céljainkat,  több  időnk  marad  a családunkra, barátainkra és végre a hobbinknak is élhetünk. 
          Végül is mi értelme az életnek, ha ezekre nem marad időnk?
        </p>
        <ul>
          <li>Több időd marad a fontos feladatokra</li>
          <li>Könnyebb lesz a munkád</li>
          <li>El lesz végezve minden (fontos) feladat</li>
          <li>Csökkenti a feladataid számát, így a stresszt</li>
          <li>Azokkal a feladatokkal foglalkozhatsz, amihez tényleg Te értesz a legjobban</li>
          <li>Azokkal a feladatokkal foglalkozhatsz, melyeket élvezel</li>
          <li>Növeli annak a szervezetnek (család, iskola, munkahely, vállalkozás) hatékonyságát, ahol<br />
          alkalmazod ezt a módszert.</li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default IndexPage
