import React from "react"
import PropTypes from "prop-types"
import "./slide.css"

const Slide = ({image, imageLink, imageLinkTitle, imageLinkOnClick, buttonTitle, buttonLink, buttonLinkTitle, buttonLinkOnClick, children }) => {

  return (
    <div className="slide">
      <div className="slide_image">
        <a href={imageLink} title={imageLinkTitle} onClick={imageLinkOnClick}>
          <img src={image} alt={imageLinkTitle} />
        </a>
      </div>
      <div className="slide_content">
        {children}
        <div className="slide_button_container">
          <a href={buttonLink} className="slide_button" title={buttonLinkTitle} onClick={buttonLinkOnClick}>
            {buttonTitle}
          </a>
        </div>
      </div>
    </div>
  )
}

Slide.propTypes = {
  image: PropTypes.string,
  imageLink: PropTypes.string,
  imageLinkTitle: PropTypes.string,
  imageLinkOnClick: PropTypes.func,
  buttonLink: PropTypes.string,
  buttonLinkTitle: PropTypes.string,
  buttonLinkOnClick: PropTypes.func,
}


export default Slide
